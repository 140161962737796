@import "../../assets/scss/style.scss";

.bouncer {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100px;
  height: 50px;
}

.bouncer div {
  width: 10px;
  height: 10px;
  background-color: $primary;
  border-radius: 50%;
  animation: bouncer 0.5s cubic-bezier(0.1, 0.3, 0.3, 0.9) infinite alternate;
  /* use https://cubic-bezier.com/ to customize the curve */
}

.bouncer div:nth-child(2) {
  animation-delay: 0.1s;
  opacity: 0.8;
}

.bouncer div:nth-child(3) {
  animation-delay: 0.2s;
  opacity: 0.6;
}

.bouncer div:nth-child(4) {
  animation-delay: 0.3s;
  opacity: 0.4;
}

@keyframes bouncer {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50px);
  }
}
