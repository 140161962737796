.errorContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 40px;
  align-items: center;
  text-align: center;
  box-shadow: 0px 1px 3px -1px #000;

  span {
    font-size: 20px;
    margin-bottom: 24px;
  }
}
