@import "../../assets/scss/style.scss";

.avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: $primary;
}

.avatar.small {
  width: 40px;
  height: 40px;
}

.avatar.medium {
  width: 60px;
  height: 60px;
}

.avatar.big {
  width: 80px;
  height: 80px;
}

.avatar.max-big {
  width: 200px;
  height: 200px;
}

.avatar span {
  font-size: 16px;
  color: white;
  font-weight: 400;
}

.avatar.medium span {
  font-size: 22px;
}
.avatar.big span {
  font-size: 30px;
}
.avatar.max-big span {
  font-size: 120px;
}

@media screen and (max-width: 1000px) {
  .avatar.big-xs {
    width: 50px;
    height: 50px;
    span {
      font-size: 20px;
    }
  }
}
